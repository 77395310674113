import { FC, useEffect, useState } from 'react';

//redux
import { addSnackbar } from 'redux/actions/actions-snackbar';

//hooks
import useAppDispatch from 'hooks/useAppDispatch';
import { useIsMounted } from 'usehooks-ts';
import useBrand from 'hooks/useBrand';

//types
import { PublishedProps } from './Published.component.d';
import { SnackbarOptions } from 'redux/slices/slice-snackbar.d';

//assets
import { ReactComponent as PublishedSVGIcon } from './assets/publishedIcon.svg';

//utils

//style
import { PublishedBox, PublishedIcon } from './style/Published.component.style';
import useHasRole from 'hooks/useHasRole';

const Published: FC<PublishedProps> = ({ published }) => {
	const brand = useBrand();

	const dispatch = useAppDispatch();
	const isMounted = useIsMounted();

	const isAdmin = useHasRole('ADMIN');
	const isSuperAdmin = useHasRole('SUPERADMIN');
	const isMiniAdmin = useHasRole('MINIADMIN');
	const isFeed = useHasRole('FEED-'+brand?.code);

	return (
		<PublishedBox>
			<PublishedIcon
				title={(isSuperAdmin || isFeed ) ? (published ? 'Nascondi' : 'Mostra') : ''}
				published={published}
				canToggle={false}
			>
				<PublishedSVGIcon />
			</PublishedIcon>
		</PublishedBox>
	);
};

export default Published;
