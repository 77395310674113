import styled, { css } from 'styled-components';

//style
import colors from 'style/palette/palette';

export const PublishedBox = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: 20px;
`;

export const PublishedIcon = styled.div<{ published: boolean; canToggle: boolean }>`
	display: flex;
	background-color: ${colors.transparent};
	border: none;

	> svg {
		width: 24px;
		margin: 0;
		padding: 0;
	}

	${({ canToggle }) =>
		canToggle &&
		css`
			cursor: pointer;
		`}

	${({ published }) =>
		published &&
		css`
			> svg path {
				fill: ${colors.green};
				fill-opacity: 1;
			}
		`}

	${({ published }) =>
		!published &&
		css`
			> svg path {
				fill: ${colors.red};
				fill-opacity: 1;
			}
		`}
`;
