import { ReactComponent as CoursesIcon } from 'assets/imgs/backoffice-page/coursesIcon.svg';
import { ReactComponent as TicketIcon } from 'assets/imgs/backoffice-page/ticketIcon.svg';
import { ReactComponent as UsersManagementIcon } from 'assets/imgs/backoffice-page/usersManagementIcon.svg';
import { ReactComponent as PaymentsIcon } from 'assets/imgs/backoffice-page/paymentsIcon.svg';
import { ReactComponent as FriendsIcon } from 'assets/imgs/backoffice-page/friendsIcon.svg';
import { ReactComponent as SendingEmailIcon } from 'assets/imgs/backoffice-page/sendingEmailIcon.svg';
import { ReactComponent as MonthlyWithdrawals } from 'assets/imgs/backoffice-page/monthlyWithdrawalsIcon.svg';
import { ReactComponent as CheckBonusSicuroIcon } from 'assets/imgs/backoffice-page/checkBonusSicuroIcon.svg';
import { ReactComponent as MyHomePageIcon } from 'assets/imgs/backoffice-page/myHomePageIcon.svg';
import { ReactComponent as AuthorIcon } from 'assets/imgs/backoffice-page/authorIcon.svg';
import { ReactComponent as LibertyPointsIcon } from 'assets/imgs/backoffice-page/libertyPointsIcon.svg';
import { ReactComponent as APLessonsManagementIcon } from 'assets/imgs/backoffice-page/assistantLessonsManagementIcon.svg';
import { ReactComponent as LessonsManagementIcon } from 'assets/imgs/backoffice-page/lessonsManagementIcon.svg';
import { ReactComponent as HighwayManagementIcon } from 'assets/imgs/backoffice-page/highwayManagementIcon.svg';
import { ReactComponent as SubscriptionsManagementIcon } from 'assets/imgs/backoffice-page/subscriptionsManagementIcon.svg';
import { ReactComponent as UnactiveManagementIcon } from 'assets/imgs/backoffice-page/unactiveManagementIcon.svg';
import { ReactComponent as SendNotificationsIcon } from 'assets/imgs/backoffice-page/sendNotificationsIcon.svg';
import { ReactComponent as CoursesHistoryIcon } from 'assets/imgs/backoffice-page/coursesHistoryIcon.svg';
import { ReactComponent as ServicesHistoryIcon } from 'assets/imgs/backoffice-page/servicesHistoryIcon.svg';
import { ReactComponent as MyCoursesManagementIcon } from 'assets/imgs/backoffice-page/myCoursesManagementIcon.svg';
import { ReactComponent as MoneyIcon } from 'assets/imgs/backoffice-page/attachMoneyIcon.svg';
import { ReactComponent as ShopIcon } from 'assets/imgs/backoffice-page/shopIcon.svg';
import { ReactComponent as StarIcon } from 'assets/imgs/backoffice-page/star-icon.svg';
import { ReactComponent as WhatsappIcon } from 'assets/imgs/backoffice-page/whatsapp-icon.svg';
import { ReactComponent as LevelsIcon } from 'assets/imgs/backoffice-page/levelsIcon.svg';
import { ReactComponent as RoadmapIcon } from 'assets/imgs/backoffice-page/roadmapIcon.svg';
import { ReactComponent as FeedIcon } from 'assets/imgs/backoffice-page/feedIcon.svg';

export interface moduleListInterface {
	title: string;
	icon: JSX.Element;
	url: string;
	isFavourite: boolean;
	roles: string[];
	brands: string[];
}

export const modulesListModel: moduleListInterface[] = [
	{
		title: 'PRODOTTI',
		icon: <CoursesIcon />,
		url: 'products',
		isFavourite: false,
		roles: ['SUPERADMIN', 'ADMIN', 'MINIADMIN', 'AUTORE', 'ANIMATORE', 'DIRETTA', 'REGISTA-LDL', 'REGISTA-MB', 'REGISTA-TCR', 'EDITOR-LDL', 'EDITOR-MB', 'EDITOR-TCR'],
		brands: ['LDL', 'VDR', 'MB', 'TCR', 'OMK'],
	},
	{
		title: 'NEGOZIO',
		icon: <ShopIcon />,
		url: 'shop',
		isFavourite: false,
		roles: ['SUPERADMIN', 'ADMIN'],
		brands: ['LDL', 'VDR', 'MB', 'TCR', 'OMK'],
	},
	{
		title: 'UTENTI',
		icon: <UsersManagementIcon />,
		url: 'users',
		isFavourite: false,
		roles: ['SUPERADMIN', 
				'ADMIN', 
				'MINIADMIN', 
				'SUPERVISORE_AUTOSTRADA',
				'ADMIN_AUTOSTRADA',
				'CONTABILITÀ', 
				'AUTORE', 
				'PERSONAL TRAINER', 
				'ASSISTENTE-PERSONALE', 
				'COACH'],
		brands: ['LDL', 'VDR', 'MB', 'TCR', 'OMK'],
	},
	{
		title: 'PAGAMENTI',
		icon: <PaymentsIcon />,
		url: 'payments',
		isFavourite: false,
		roles: ['SUPERADMIN', 'ADMIN', 'MINIADMIN', 'CONTABILITÀ'],
		brands: ['LDL', 'VDR', 'MB', 'TCR', 'OMK'],
	},
	{
		title: 'VECCHI PAGAMENTI',
		icon: <PaymentsIcon />,
		url: 'oldpayments',
		isFavourite: false,
		roles: ['ADMIN', 'SUPERADMIN', 'CONTABILITÀ', 'MINIADMIN'],
		brands: ['LDL', 'VDR', 'MB', 'TCR'],
	},
	// {
	// 	title: 'AMICI',
	// 	icon: <FriendsIcon />,
	// 	url: '',
	// 	isFavourite: false,
	// },
	// {
	// 	title: 'INVIO MAIL',
	// 	icon: <SendingEmailIcon />,
	// 	url: '',
	// 	isFavourite: false,
	// },
	{
		title: 'AMMINISTRAZIONE',
		icon: <MoneyIcon />,
		url: 'administration',
		isFavourite: false,
		roles: ['SUPERADMIN', 'CONTABILITÀ'],
		brands: ['LDL', 'VDR', 'MB', 'TCR', 'OMK'],
	},
	{
		title: 'ATTIVI E DISATTIVI',
		icon: <UnactiveManagementIcon />,
		url: 'activeinactive',
		isFavourite: false,
		roles: ['ADMIN', 'SUPERADMIN', 'AUTORE'],
		brands: ['LDL', 'VDR', 'MB', 'TCR', 'OMK'],
	},
	// {
	// 	title: 'CONTR. BONUS SIC.',
	// 	icon: <CheckBonusSicuroIcon />,
	// 	url: '',
	// 	isFavourite: false,
	// },
	// {
	// 	title: 'LA MIA HOMEPAGE',
	// 	icon: <MyHomePageIcon />,
	// 	url: '',
	// 	isFavourite: false,
	// },
	// {
	// 	title: 'AUTORE',
	// 	icon: <AuthorIcon />,
	// 	url: '',
	// 	isFavourite: false,
	// },
	{
		title: 'PUNTI',
		icon: <LibertyPointsIcon />,
		url: 'points',
		isFavourite: false,
		roles: [
			'SUPERADMIN',
			'ADMIN',
			'CONTABILITÀ',
			'MINIADMIN',
			'AUTORE',
			'ASSISTENTE-PERSONALE',
			'SUPERVISORE_AUTOSTRADA',
			'ADMIN_AUTOSTRADA',
			'PERSONAL TRAINER',
			'COACH',
		],
		brands: ['LDL', 'VDR', 'MB', 'TCR', 'OMK'],
	},
	// {
	// 	title: 'GESTIONE LEZIONI',
	// 	icon: <LessonsManagementIcon />,
	// 	url: '',
	// 	isFavourite: false,
	// },
	{
		title: 'LEZIONI AP',
		icon: <APLessonsManagementIcon />,
		url: 'aplessons',
		isFavourite: false,
		roles: ['SUPERADMIN', 'SUPERVISORE_AUTOSTRADA', 'ADMIN_AUTOSTRADA'],
		brands: ['LDL'],
	},
	{
		title: 'AUTOSTRADA',
		icon: <HighwayManagementIcon />,
		url: 'highwayroutes',
		isFavourite: false,
		roles: ['SUPERADMIN', 'SUPERVISORE_AUTOSTRADA', 'ADMIN_AUTOSTRADA'],
		brands: [],
	},
	{
		title: 'LE MIE LEZIONI',
		icon: <LessonsManagementIcon />,
		url: 'mylessons',
		isFavourite: false,
		roles: [
			'SUPERADMIN', 
			'ADMIN', 
			'SUPERVISORE_AUTOSTRADA', 
			'ADMIN_AUTOSTRADA', 
			'ASSISTENTE-PERSONALE', 
			'PERSONAL TRAINER', 
			'COACH'],
		brands: ['LDL', 'MB', 'TCR', 'OMK'],
	},
	{
		title: 'TRAGUARDI',
		icon: <CoursesHistoryIcon />,
		url: 'levels',
		isFavourite: false,
		roles: ['SUPERADMIN', 'ADMIN', 'COACH'],
		brands: ['LDL'],
	},
	// {
	// 	title: 'GESTIONE ABBON.',
	// 	icon: <SubscriptionsManagementIcon />,
	// 	url: '',
	// 	isFavourite: false,
	// },
	// {
	// 	title: 'GESTIONE DISATTIVI',
	// 	icon: <UnactiveManagementIcon />,
	// 	url: '',
	// 	isFavourite: false,
	// },
	{
		title: 'NOTIFICHE',
		icon: <SendNotificationsIcon />,
		url: 'sendNotifications',
		isFavourite: false,
		roles: ['SUPERADMIN', 'ADMIN', 'AUTORE'],
		brands: ['LDL', 'VDR', 'MB', 'TCR', 'OMK'],
	},
	{
		title: 'TESTIMONIANZE',
		icon: <StarIcon />,
		url: 'testimonials',
		isFavourite: false,
		roles: ['SUPERADMIN', 'ADMIN'],
		brands: ['LDL', 'VDR', 'MB', 'TCR', 'OMK'],
	},
	{
		title: 'WHATSAPP',
		icon: <WhatsappIcon />,
		url: 'whatsapp',
		isFavourite: false,
		roles: ['SUPERADMIN', 'ADMIN', 'SUPERVISORE_AUTOSTRADA', 'ADMIN_AUTOSTRADA',],
		brands: ['LDL', 'MB', 'TCR', 'OMK'],
	},
	{
		title: 'ROADMAP',
		icon: <RoadmapIcon />,
		url: 'roadmap',
		isFavourite: false,
		roles: ['SUPERADMIN', 'ROADMAP', 'ROADMAP-LDL', 'ROADMAP-VDR', 'ROADMAP-MB', 'ROADMAP-TCR'],
		brands: [],
	},
	{
		title: 'FEED',
		icon: <FeedIcon />,
		url: 'feed',
		isFavourite: false,
		roles: ['SUPERADMIN', 'FEED'],
		brands: ['LDL', 'VDR', 'MB', 'TCR', 'OMK'],
	},
	// {
	// 	title: 'STORICO CORSI',
	// 	icon: <CoursesHistoryIcon />,
	// 	url: '',
	// 	isFavourite: false,
	// },
	// {
	// 	title: 'STORICO SERVIZI',
	// 	icon: <ServicesHistoryIcon />,
	// 	url: '',
	// 	isFavourite: false,
	// },
	// {
	// 	title: 'GESTIONE MIEI CORSI',
	// 	icon: <MyCoursesManagementIcon />,
	// 	url: '',
	// 	isFavourite: false,
	// },
];
