import { FC } from 'react';

//hooks
import useAppDispatch from 'hooks/useAppDispatch';

//types
import { FeedPostsTableProps } from './FeedPostsTable.component.d';
import { FeedPostsTableColumn } from './FeedPostsTable.component.d';

//utils
import { DateTime } from 'luxon';

//assets
import { ReactComponent as LinkIcon } from './assets/linkIcon.svg';
import DefaultPostPic from './assets/defaultPostPic.png';

//components
import Visible from './Visible';
import Published from './Published';
import RowButtons from './RowButtons';
import IsPinned from '../../FeedPostDetailPage/IsPinned/IsPinned.component';

//style
import { Table, TableBody, TableBodyCell, TableBodyRow, TableHead, TableHeadCell, TableHeadRow, SellImage, PostName, Subscriptions } from './style/FeedPostsTable.style';
import useHasRole from 'hooks/useHasRole';

const url = window.location.origin;
const online = !url.includes('localhost');
const test = url.includes('ldl-test') || !online;

const FeedPostsTable: FC<FeedPostsTableProps> = ({ data, putPost }) => {

	const dispatch = useAppDispatch();

	var columns: FeedPostsTableColumn[] = [
		{ label: '', width: '3%' },
		{ label: 'ID', width: '2%' },
		// { label: 'IMG', width: '10%' },
		{ label: 'NOME', width: '50%' },
		{ label: 'DATA', width: '20%' },
		// { label: 'METODI PAGAMENTO', width: '5%' },
		// { label: 'PESO', width: '5%' },
		{ label: 'VISIBILE', width: '' },
		{ label: 'PUBBLICATO', width: '' },
		{ label: 'HOT', width: '' },
		// { label: 'BRAND', width: '5%' },
		// { label: 'PRODOTTI', width: '4%' },
		// {label:'AUTOSTRADA',width: '1%'},
		// {label:'LEZ. AP',width: '1%'},
		// { label: 'STATS', width: '5%' },
		// { label: 'NOTE', width: '2%' },
	];

	const isAdmin = useHasRole('ADMIN');
	const isSuperAdmin = useHasRole('SUPERADMIN');
	const isMiniAdmin = useHasRole('MINIADMIN');

	const hasAdminRole = isAdmin || isSuperAdmin;

	const isValidDate = (dateToCheck: Date | string): boolean => {
		if (!dateToCheck) return false;
		try {
			const testDate = new Date(dateToCheck);
			return true;
		} catch (err) {
			return false;
		}
	};

	const showDate = (originalDate: string): string => {
		if (!originalDate) return "";
		const dateDate = new Date(originalDate);
		const utcDateTime = DateTime.fromISO(originalDate, { zone: 'utc' });
		const localDateTime = utcDateTime.toLocal();
		// if (dateDate.getHours()>=0 && dateDate.getHours()<=3) return localDateTime.toFormat('dd/MM/yyyy');
		return localDateTime.toFormat('dd/MM/yyyy');
	}

	return (
		<>
			<Table>
				<TableHead>
					<TableHeadRow>
						{columns.map((c) => (
							<TableHeadCell
								key={c.label}
								width={c.width}
								// onClick={() => handleSort(key)}
							>
								{c.label.toUpperCase()}
								{/* {sortColumn === c && <span>{sortDirection === 'asc' ? '⬆️' : '⬇️'}</span>} */}
							</TableHeadCell>
						))}
					</TableHeadRow>
				</TableHead>
				<TableBody>
					{data.map((row, index) => (
						<TableBodyRow key={index}>
							<TableBodyCell
								key={index + '_buttons'}
								id={index + '_buttons'}
							>
								<RowButtons postId={row.id} />
							</TableBodyCell>

							<TableBodyCell
								key={index + '_id'}
								id={index + '_id'}
							>
								{row.id}
							</TableBodyCell>

							{/* <TableBodyCell
								key={index + '_image'}
								id={index + '_image'}
							>
								{row.customImage?.url && <SellImage
									alt=''
									src={row.customImage?.url ? row.customImage.url : DefaultPostPic}
								/>}
							</TableBodyCell>						 */}

							<TableBodyCell
								key={index + '_name'}
								id={index + '_name'}
							>
								<PostName>
									{row.title}
								</PostName>
							</TableBodyCell>
							
							<TableBodyCell
								key={index + '_datetime'}
								id={index + '_datetime'}
							>
								<PostName>
									{row.publishDate ? showDate(row.publishDate) : ""}
								</PostName>
							</TableBodyCell>

							<TableBodyCell
								key={index + '_visible'}
								id={index + '_visible'}
							>
								<Visible
									postId={row.id}
									visible={row.visible}
									putPost={putPost}
								/>
							</TableBodyCell>
							<TableBodyCell
								key={index + '_published'}
								id={index + '_published'}
							>
								<Published
									published={row.status === 'PUBLISHED'}
								/>
							</TableBodyCell>
							<TableBodyCell
								key={index + '_pinned'}
								id={index + '_pinned'}
							>
								<IsPinned
									feedpostId={row.id}
									pinned={row.pinned}
									putPost={putPost}
								/>
							</TableBodyCell>
						</TableBodyRow>
					))}
				</TableBody>
			</Table>

			{/* {totalElements > 0 && (
				<TableFooter>
					<FooterElementsCount>{footerElementsCount({ currentPage: pageNumber, totalPages, totalElements, pageSize })}</FooterElementsCount>
					<FooterNavigationButton
						onClick={prevPageHandler}
						disabled={pageNumber === 1}
					>
						<ChevronLeftIcon />
					</FooterNavigationButton>
					<FooterNavigationButton
						onClick={nextPageHandler}
						disabled={pageNumber === totalPages}
					>
						<ChevronRightIcon />
					</FooterNavigationButton>
				</TableFooter>
			)} */}
		</>
	);
};

export default FeedPostsTable;
